import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
}

const TablesWidget10: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste des patients</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Member
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
        {/* begin::Table container */}
          {/* begin::Table */}
            {/* begin::Table head */}
            {/* end::Table head */}
            {/* begin::Table body */}
    <div className="d-flex flex-wrap">
      {[...Array(30)].map((_, index) => (
        <div key={index} className='d-flex align-items-center me-5 mb-5'>
          <div className='symbol symbol-100px me-5'>
            <img src={toAbsoluteUrl(`/media/avatars/300-${index + 1}.jpg`)} alt='' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='form' className='text-dark fw-bold text-hover-primary fs-6'>
              Ana Simmons
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              HTML, JS, ReactJS
            </span>
          </div>
        </div>
      ))}
    </div>

      </div>
  )
}
export {TablesWidget10}
