/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useFormik} from 'formik'

interface props {
  dataPatient,
  dataInfoPatient
}

const ContentPatient = ({  dataPatient , dataInfoPatient }: props) => {
  useEffect(() => {
  }, [])

  return (  
    <>
      <div className="card mb-10">
        <div className="card-header p-2">
          <div className="card-toolbar flex-column w-100">
            <div className="d-flex flex-column w-100">
              <div className="fs-1 fw-bolder colorDark">
                Patient
              </div>
            </div>
          </div>
        </div>

        <div className="card-body p-2">
          <div className="p-4">
           
            <div className='contentBox mt-0'>

            <div className="cardPatientInfo">
            <div className="p-0 row col-md-12">
              <div className="d-flex">

                <div className='col-md-4 flex-column rightborder  border-primary '>
                  <div className="p-4">
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                      <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start flex-wrap'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">ANA Simmone</span>
                                </div>

                                <div className="d-flex flex-wrap fw-semibold fs-4 pe-2">
                                  <span><b>Age :</b> 53 ans</span>
                                </div>
                            </div>
                            <div className='d-flex flex-column'></div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='col-md-4 flex-column rightborder border-primary'>
                  <div className="p-4">
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                      <div className='flex-grow-1'>
                          <div >
                            <div className='d-flex flex-column'> </div>
                            <div className='d-flex flex-column'>
                                <div className="d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2">
                                  <span><b>Date de naissance :</b> 15/01/1971</span>
                                </div>

                                <div className="d-flex flex-wrap fw-semibold fs-4 pe-2">
                                  <span><b>Sexe à la naissance :</b> Femme</span>
                                  
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-4 flex-column '>
                  <div className="p-4">
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                      <div className='flex-grow-1'>
                          <div >
                            <div className='d-flex flex-column'> </div>
                            <div className='d-flex flex-column'>
                                <div className="d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2">
                                  <span><b>Date d'admission :</b> 12/12/2023</span>
                                </div>

                                <div className="d-flex flex-wrap fw-semibold fs-4 pe-2">
                                  <span><b>Contact</b> : 06 77 63 44 54</span>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>


              <div className="contentFilter row col-md-12 d-none">

                <div className="mb-5 col-md-4">
                    <label className="form-label fs-6 fw-bold">Nom</label>
                    <input type="text" className="form-control fw-bolder" 
                    {...dataPatient.getFieldProps('birthName')} disabled ></input>
                </div>

                <div className="mb-5 col-md-4">
                    <label className="form-label fs-6 fw-bold">Prénom</label>
                    <input type="text" className="form-control"
                    {...dataPatient.getFieldProps('firstNames')} disabled></input>
                </div>

                <div className="mb-5 col-md-4">
                  <label className="form-label fs-6 fw-bold">Date de naissance</label>
                  <input type="date" className="form-control"
                  {...dataPatient.getFieldProps('birthDate')} disabled ></input>
                </div>

              </div>

              <div className="contentFilter row col-md-12 d-none">

                

                <div className="mb-5 col-md-4">
                  <label className="form-label fs-6 fw-bold">Sexe à la naissance</label>
                  <select className="form-select"
                  {...dataPatient.getFieldProps('sex')} disabled >
                      <option value=""></option>
                      <option value="homme">Homme</option>
                      <option value="femme">Femme</option>
                  </select>
                </div>

                <div className="mb-5 col-md-4">
                  <label className="form-label fs-6 fw-bold">Age</label>
                  <input type="number" className="form-control" {...dataPatient.getFieldProps('age')} disabled></input>
                </div>

                <div className="mb-5 col-md-4">
                  <label className="form-label fs-6 fw-bold">Date d'admission</label>
                  <input type="date" className="form-control"
                  {...dataPatient.getFieldProps('dateAdmission')} disabled ></input>
                </div>

              </div>

          
            </div>
          </div>

          <div className="p-4">
            <h2 className='colorPrimary  m-b-5'>Service hospitalier</h2>
            <div className="contentBox mt-5">
            <div className="contentFilter row col-md-12">

              <div className="mb-5  col-md-4">
                  <label className="form-label fs-6 fw-bold">De quel service hospitalier faites-vous partie ?</label>
                  <select 
                    className="form-select"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    data-kt-user-table-filter="sexe"
                    data-hide-search="true" >
                    <option value=""></option>
                    <option value="service1">Service 1</option>
                    <option value="service2">Service 2</option>
                </select>
              </div>

              </div>
            </div>
          </div>

          <div className="p-4">
            <h2 className='colorPrimary  m-b-5'>Evènement cardio-vasculaire</h2>
            <div className="contentBox mt-5">
                <div className="contentFilter row col-md-12 mb-5">

                  <div className="col-md-4">
                      <div className='colorPrimary fw-bolder fs-4'>Cardiovasculaire</div>
                      <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                        <input className="form-check-input" type="radio" name="cardio" value="" />
                        <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                          Revascularisation coronarienne hors urgences
                        </label>
                      </div>
                      <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                        <input className="form-check-input" type="radio" name="cardio" value="" />
                        <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                          Syndrome coronarien aigu
                        </label>
                      </div>
                  </div>

                  <div className="col-md-4">
                    <div className='colorPrimary fw-bolder fs-4'>Neurovasculaire</div>
                    <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                        <input className="form-check-input" type="radio" name="cardio" value="" />
                        <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                        AVC ischémique athéromateux
                        </label>
                    </div>
                    <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                        <input className="form-check-input" type="radio" name="cardio" value="" />
                        <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                        AIT artéro-thrombotique
                        </label>
                    </div>
                  </div>

                  <div className="col-md-4">
                      <div className='colorPrimary fw-bolder fs-4'>Maladie artérielle périphérique</div>
                      <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                        <input className="form-check-input" type="radio" name="cardio" value="" />
                        <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                        Revascularisation artérielle périphérique
                        </label>
                      </div>
                      <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                          <input className="form-check-input" type="radio" name="cardio" value="" />
                          <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                          Revascularisation carotidienne
                          </label>
                      </div>
                  </div>
                </div>
            </div>
          </div>

          <div className="p-4">
            <h2 className='colorPrimary  m-b-5'>Date de sortie</h2>
            <div className="contentBox mt-5">
              <div className="contentFilter row col-md-12">
                <div className="mb-5 col-md-4">
                  <input type="date" className="form-control"
                  {...dataPatient.getFieldProps('PS.dateSortie')}></input>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-none">
          <label className="form-label fs-6 fw-bold">Médecin traitant</label>
          <div className='w-100'>
          <span className='fs-6'>
          {dataInfoPatient.equipes.medecin}
          <div className='w-100'>
          <span  className='fw-bold'>Etablissement : </span>
          <span>NANTES</span>
          </div>
          <div className='w-100'>
          <span  className='fw-bold'>Email : </span>
          <span>NANTES</span>
          </div>
          <div className='w-100'>
          <span  className='fw-bold'>Phone : </span>
          <span>NANTES</span>
          </div>
          </span>

          </div>
          </div>

          <div className="p-4">
            <h2 className='colorPrimary  m-b-5'>Equipe soignante</h2>
            <div className="contentBox mt-5">

              <div className="contentFilter row col-md-12 mb-5">

                <div className="col-md-4">
                  <label className="form-label fs-6 fw-bold">Médecin traitant</label>
                  <input type="text" className="form-control fw-bolder"
                  {...dataPatient.getFieldProps('equipes.medecin')} disabled></input>
                </div>

                <div className="col-md-4">
                  <label className="form-label fs-6 fw-bold">Endocrino-diabétologue</label>
                  <input type="text" className="form-control fw-bolder"
                  {...dataPatient.getFieldProps('equipes.diabete')} disabled></input>
                </div>

                <div className="col-md-4">
                    <label className="form-label fs-6 fw-bold">Vasculaire / angeiologue</label>
                  <input type="text" className="form-control fw-bolder"
                  {...dataPatient.getFieldProps('equipes.vasculaire')} disabled></input>
                </div>
              </div>

              <div className="contentFilter row col-md-12 mb-5">

                <div className="col-md-4">
                  <label className="form-label fs-6 fw-bold">Neurologue</label>
                  <input type="text" className="form-control fw-bolder"
                  {...dataPatient.getFieldProps('equipes.neurologue')} disabled></input>
                </div>

                <div className="col-md-4">
                  <label className="form-label fs-6 fw-bold">Cardiologue</label>
                  <input type="text" className="form-control fw-bolder"
                  {...dataPatient.getFieldProps('equipes.cardiologue')} disabled></input>
                </div>

                <div className="col-md-4">
                    <label className="form-label fs-6 fw-bold">Psychiatre</label>
                  <input type="text" className="form-control fw-bolder"
                  {...dataPatient.getFieldProps('equipes.psychiatre')} disabled></input>
                </div>
              </div>

            </div>
          </div>

          <div className="p-4">
            <h2 className='colorPrimary  m-b-5'>Surveillances nécessaires</h2>
            <div className="contentBox mt-5">

              <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                <div className='col-md-3'>

                  <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                    <input className="form-check-input" type="checkbox" value="" />
                    <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                      HTA
                    </label>
                  </div>

                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4">
                      Dernière mesure de référence 
                    </label>
                    <div className='col-md-6'>
                      <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">PAD</label>
                      <input type="text" className="form-control fw-bolder float-start wInput"></input>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>

                  <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">PAS</label>
                  <div className='input-group wInput'>
                    <input type="text" className="form-control fw-bolder float-start"></input>
                  </div>

                </div>
              </div>

              <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                <div className='col-md-3'>

                  <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                    <input className="form-check-input" type="checkbox" value="" />
                    <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                    LDLc {'>'} objectif
                    </label>
                  </div>

                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4">
                      Dernière mesure de référence 
                    </label>
                    <div className='col-md-6'>

                    <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">LDLc</label>
                    <div className='input-group wInput'>
                        <input type="text" className="form-control fw-bolder float-start"></input> 
                        <span className="input-group-text" >mg/l</span>
                    </div>

                      
                      
                    </div>
                  </div>
                </div>

                <div className='col-md-3 mt-4'>

                  <input className="form-check-input" type="checkbox" value="" />
                  <label className="form-check-label colorSecondary fs-6 fw-bold mx-3" htmlFor="flexCheckDefault">
                  HDL non dosable
                  </label>
                
                </div>
              </div>

              <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                <div className='col-md-3'>

                  <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                    <input className="form-check-input" type="checkbox" value="" />
                    <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                    Diabète
                    </label>
                  </div>

                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4">
                      Dernière mesure de référence 
                    </label>
                    <div className='col-md-6 '>
                      <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">HbA1c</label>
                      <div className='input-group wInput'>
                        <input type="text" className="form-control fw-bolder float-start"></input> 
                        <span className="input-group-text" >%</span>
                      </div>
                     
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                </div>
              </div>

              <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                <div className='col-md-3'>

                  <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                    <input className="form-check-input" type="checkbox" value="" />
                    <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                    Fonction rénale
                    </label>
                  </div>

                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4">
                      Dernière mesure de référence 
                    </label>
                    <div className='col-md-6'>
                      <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">Créatine</label>
                      <div className='input-group wInput'>
                        <input type="text" className="form-control fw-bolder float-start"></input> 
                        <span className="input-group-text" >µmol/L</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                </div>
              </div>

              <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                <div className='col-md-3'>

                  <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                    <input className="form-check-input" type="checkbox" value="" />
                    <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                    Surcharge pondérale
                    </label>
                  </div>

                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4">
                      Dernière mesure de référence 
                    </label>
                    <div className='col-md-6'>
                      <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">Poids</label>
                      <div className='input-group wInput'>
                        <input type="text" className="form-control fw-bolder float-start"
                        {...dataPatient.getFieldProps('PS.poids')}></input> 
                        <span className="input-group-text" >kg</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>

                  <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">Taille</label>
                  <div className='input-group wInput'>
                    <input type="text" className="form-control fw-bolder float-start"
                    {...dataPatient.getFieldProps('PS.taille')}></input> 
                    <span className="input-group-text" >m</span>
                  </div>

                </div>
              </div>

              <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                <div className='col-md-3'>

                  <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                    <input className="form-check-input" type="checkbox" value="" />
                    <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                    Tabac
                    </label>
                  </div>

                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4">
                      Dernière mesure de référence 
                    </label>
                    <div className='col-md-6'>
                      <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">Nombre</label>
                      <div className='input-group wInput'>
                        <input type="text" className="form-control fw-bolder float-start"
                        {...dataPatient.getFieldProps('PS.NombreTabac')}></input> 
                        <span className="input-group-text" >/jour</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

const ContentObjectifs = ({  dataPatient }: props) => {
  useEffect(() => {
  }, [])

  return (  
    <>
      <div className="card mb-10">
          <div className="card-header p-2">
            <div className="card-toolbar flex-column w-100">
              <div className="d-flex flex-column w-100">
                <div className="fs-1 fw-bolder colorDark">
                  Objectifs thérapeutiques personnalisés
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-2">
            <div className="p-4">
              {/*<h2 className='colorPrimary  m-b-5'>xxx</h2>*/}
              <div className="contentBox mt-5">

                <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                  <div className='col-md-3'>

                    <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                      <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                        TA
                      </label>
                    </div>

                  </div>

                  <div className='col-md-3'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">PAD {'<'}</label>
                    <input type="text" className="form-control fw-bolder float-start wInput"
                    {...dataPatient.getFieldProps('PS.perso.PAD')}></input>
                  </div>

                  <div className='col-md-3'>

                    <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">PAS {'<'}</label>
                    <input type="text" className="form-control fw-bolder float-start wInput"
                    {...dataPatient.getFieldProps('PS.perso.PAS')}></input>

                  </div>
                </div>

                <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                  <div className='col-md-3'>

                    <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                      <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                        LDL
                      </label>
                    </div>

                  </div>

                  <div className='col-md-3'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">LDLc {'<'}</label>
                    
                    <div className='input-group wInput'>
                      <input type="text" className="form-control fw-bolder float-start"
                      {...dataPatient.getFieldProps('PS.perso.LDLC')}></input> 
                      <span className="input-group-text" >g/l</span>
                    </div>

                  </div>

                  <div className='col-md-3'>

                  </div>
                </div>

                <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                  <div className='col-md-3'>

                    <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                      <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                        Diabéte
                      </label>
                    </div>

                  </div>

                  <div className='col-md-3'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">HbA1c {'<'}</label>
                    
                    <div className='input-group wInput'>
                      <input type="text" className="form-control fw-bolder float-start"
                      {...dataPatient.getFieldProps('PS.perso.HBA')} disabled></input> 
                      <span className="input-group-text" >%</span>
                    </div>

                  </div>

                  <div className='col-md-3'>

                  </div>
                </div>

                <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                  <div className='col-md-3'>

                    <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                      <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                      Insufficance rénale
                      </label>
                    </div>

                  </div>

                  <div className='col-md-3'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel"></label>
                    <input type="text" className="form-control fw-bolder float-start wInput"
                    {...dataPatient.getFieldProps('PS.perso.renale')} disabled></input>
                  </div>

                  <div className='col-md-3'>

                    
                  </div>
                </div>

                <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                  <div className='col-md-3'>

                    <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                      <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                      Tabac
                      </label>
                    </div>

                  </div>

                  <div className='col-md-3'>
                    <label className="form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel">Nombre</label>
                    <input type="text" className="form-control fw-bolder float-start wInput"
                    {...dataPatient.getFieldProps('PS.perso.NombreTabac')} disabled></input>
                  </div>

                  <div className='col-md-3'>

                    
                  </div>
                </div>

              
              </div>
            </div>
            <div className="p-4">
              <h2 className='colorPrimary  m-b-5'>Traitement médicamenteux à la sortie</h2>
              <div className="contentBox mt-5">
                <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
                <div className='col-md-3'>
                  <div className="form-check form-check-custom  form-check-sm w-100 mt-4">
                      <input className="form-check-input" type="checkbox" value="" />
                      <label className="form-check-label colorSecondary fs-6 fw-bold" htmlFor="flexCheckDefault">
                      Antithrombotiques multiples
                      </label>
                  </div>
                </div>
                  
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

const ContentSuivis = () => {

  useEffect(() => {
  }, [])

  return (  
    <>
    <div className="card  mb-10">
        <div className="card-header p-2">
          <div className="card-toolbar flex-column w-100">
            <div className="d-flex flex-column w-100">
              <div className="fs-1 fw-bolder colorDark">
                Suivis particuliers
              </div>
            </div>
          </div>
        </div>

        <div className="card-body p-2">
          <div className="p-4">
            <h2 className='colorPrimary  m-b-5'>Note</h2>
            <div className="contentBox mt-5">
            <div className="contentFilter row col-md-12 mb-5 d-flex flex-row">
              <div className='col-mdd-12'>
              <textarea
              className='form-control mb-3'
              rows={4}
              data-kt-element='input'
              placeholder='Saisissez une note'
              value=""
            ></textarea>
              </div>
            </div>
            

            </div>
          </div>
        </div>
    </div>
    </>
  )
}

const BuilderPPCP = () => {

  const initialValues = {
    birthName: "ANA",
    firstNames: "Simmone",
    birthDate: "1971-01-15",// time stamp
    sex: "femme",
    age: 53,
    dateAdmission: "2023-12-12",
    equipes:{
      medecin:"Docteur Emma MARCHAND - NANTES",
      diabete:"Docteur Emma MARCHAND - REZE",
      vasculaire:"Docteur Emma MARCHAND - ORVAULT",
      neurologue:"Docteur Emma MARCHAND - NANTES",
      cardiologue:"Docteur Emma MARCHAND - INDRE",
      psychiatre:"Docteur Emma MARCHAND"
    },
    PS:{
      dateSortie: "2024-04-03",
      poids: 85,
      taille : 1.80,
      NombreTabac: 5,
      perso:{
        PAD: 80,
        PAS: 130,
        LDLC: 0.55,
        HBA: 7.00,
        renale: "Créatine",
        NombreTabac: 0
      }
    }
  }

  const formDataPatient = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
     
      try {
      
      } catch (error) {
       
      }
    },
  })

  return (
    <>
      <form>
        <ContentPatient   dataPatient={formDataPatient}  dataInfoPatient={initialValues} />
        <ContentObjectifs dataPatient={formDataPatient}  dataInfoPatient={initialValues} />
        <ContentSuivis />
        <div className="card  mb-10">
          <div className='card-footer py-6'>

            <div className='row'>
              <div className='col-lg-5'></div>
              <div className='col-lg-7'>

                <button type='button' className='btn btn-active-light btn-color-muted me-2'>
                  <span className='indicator-label'>Enregistrer</span>
                </button>


                <button type='button' className='btn btn-primary me-2'>
                  <span className='indicator-label'>Soumettre</span>
                </button>

                

              </div>
            </div>

          </div>
        </div>
      </form>
    </>
  )
}

export {BuilderPPCP}
