/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from 'react';
import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../core/Auth';


export function TwoFactorSMS() {  
  const intl = useIntl()

  const {twoFactor, setTwoFacter} = useAuth()

  let navigate = useNavigate(); 
  const routeChange = async () =>{ 


    localStorage.setItem("twoFactor", "true")

    setTwoFacter(true);
    //localStorage.setItem(twoFactor,false);
    

    navigate("/dashboard");

  }
  
  return (
    <>
      <form className='form w-100 mb-20'>
        <div  className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid'>
          <img alt='DIGICAP' className='mb-8 logoAuthSmall' src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Logo_Cercle_Music.png" />
          <h1 className="mb-8 colorTitle">{intl.formatMessage({id: 'AUTHENTICATION.Name.PAGE'})}</h1>
        </div>

        <div className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid '>
          
          <div className='mt-2 w-400px'>

              <h2 className="fw-bold align-items-center text-gray-900 mb-4">Vérification à deux facteurs</h2>
              <div className="text-muted fw-semibold fs-5 mb-5">Entrez le code de vérification que nous avons envoyé à</div>
              <div className="fw-bold text-gray-900 fs-3">** ** ** 24</div>
             

              <div className="fw-bold text-start text-gray-900 fs-6 mt-10 mb-2 ms-1">Tapez votre code de sécurité à 6 chiffres</div>


              
              <div className="d-flex flex-wrap flex-stack mb-10" >                
                
                <input type="number" min={1} max={9}  className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   />
                <input type="number" min={1} max={9} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   />
                <input type="number" min={1} max={9} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   /> 
                <input type="number" min={1} max={9} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   /> 
                <input type="number" min={1} max={9} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   />                     
                <input type="number" min={1} max={9} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   /> 
              </div>

              <button type='button' className='btn btn-primary mb-4 BtnRound py-30 px-10'
              onClick={() => routeChange()}>
                <span className='indicator-label'>
                  Valider
                </span>
              </button>

              <div className="text-center fw-semibold fs-5">
                  <span className="text-muted me-1">Vous n'avez pas reçu le code ?</span>
                  <a href="#" className="link-primary fs-5 me-1">Renvoyer</a>
              </div>

          </div>
        </div>
      </form>
    </>
    
  )
}
