/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';


export function TwoFactor() {
  const intl = useIntl()
  let navigate = useNavigate(); 
  const routeChange = (path) =>{ 
    navigate(path);
  }
  
  return (
    <>
      <form className='form container px-14 mb-14'>
        <div  className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid'>
          <img alt='DIGICAP' className='mb-8 logoAuthSmall' src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Logo_Cercle_Music.png" />
          <h1 className="mb-8 colorTitle">{intl.formatMessage({id: 'AUTHENTICATION.Name.PAGE'})}</h1>
        </div>

        <div className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid '>
          
          <div className='mt-2 w-400px'>

              <h3 className="fw-bold d-flex align-items-center text-gray-900 mb-4">Confirmation d’identité à deux facteurs</h3>
              <input type="radio" className="btn-check" name="auth_option" value="apps"  id="two_factor_authentication_option_1" />
              <label className="btn btn-outline btn-outline-dashed px-7 py-3 d-flex align-items-center mb-4 w-100 BorderColorGray" htmlFor="two_factor_authentication_option_1">
                  <span className="d-block fw-semibold text-start">    
                      <i className="fas fa-envelope fs-2"></i>      
                      <span className="text-gray-900 fw-bold d-block fs-4 mb-2">
                          Par e-mail : mar***@gmail.com 
                      </span>
                  </span>
              </label> 

              <input type="radio" className="btn-check" name="auth_option" value="sms" id="two_factor_authentication_option_2" />
              <label className="btn btn-outline btn-outline-dashed px-7 py-3 d-flex align-items-center mb-6 w-100 BorderColorGray" htmlFor="two_factor_authentication_option_2">
                <span className="d-block fw-semibold text-start">     
                    <i className="fas fa-mobile fs-2"></i>                            
                    <span className="text-gray-900 fw-bold d-block fs-4 mb-2">
                        Par SMS : ** ** ** 24
                    </span>
                </span>
              </label>   


              <button type='submit' className='btn btn-primary mb-0 BtnRound py-30 px-10'
              onClick={() => routeChange('sms')}>
                <span className='indicator-label'>
                  Continuer
                </span>
              </button>
          </div>
        </div>
      </form>
    </>
    
  )
}
