import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'

const DashboardPage = () => {


  let navigate = useNavigate(); 
  const routeChange = (path) =>{ 
    navigate(path);
  }

  
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-column-fluid'>
        <div className='app-container container-fluid'>
          <div className='row  col-xl-12 '>
            
            <div className='offset-md-2 col-xl-4'>
              <div className="card h-md-100 "> 
              
                <div className="card-body d-flex flex-column">  
                
                    <div className="mb-2">
                      
                        <h1 className="fw-bolder text-gray-800 text-center lh-lg">   
                            Création d’un PPCP<br/>    
                            <span className="fw-semibold">Les PPCP pré-renseignés par vos patients sont enregistrés ici.</span>
                        </h1>
                      
                        <div className="py-10 text-center">
                          <img src={toAbsoluteUrl('media/svg/illustrations/easy/2.svg')} className="w-200px pb-10" alt="" />
                        </div>
                    
                    </div>
                  
                    <div className="text-center mb-1">
                        <span  onClick={() => routeChange('/patientsnonvalide')} className="btn btn-sm btn-primary me-2">Voir la liste</span>
                    </div>
                  
                </div>
          
              </div>
            </div>
            <div className='col-xl-4'>
              <div className="card h-md-100 "> 
              
                <div className="card-body d-flex flex-column">  
                
                    <div className="mb-2">
                      
                        <h1 className="fw-bolder text-gray-800 text-center lh-lg">   
                          Suivis patients<br/>    
                            <span className="fw-semibold">Le suivi de vos patients se situent dans cette section. </span>
                        </h1>
                      
                        <div className="py-10 text-center">
                          <img src={toAbsoluteUrl('media/svg/illustrations/easy/1.svg')} className="w-200px" alt="" />
                        </div>
                    
                    </div>
                  
                    <div className="text-center mb-1">
                        <span onClick={() => routeChange('/patientsvalide')} className="btn btn-sm btn-primary me-2">Voir la liste</span>
                    </div>
                  
                </div>
          
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const accountBreadCrumbs: Array<PageLink> = []
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
