import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { KTIcon } from '../../../_metronic/helpers';
import { Dropdown1 } from '../../../_metronic/partials/content/dropdown/Dropdown1';
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils';
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';

type Props = {
  className: string;
  typeGraph
};

/**'scatter' */
const ChartsWidget: React.FC<Props> = ({ className,typeGraph }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [selectedValue, setSelectedValue] = useState<string>('');

  const getChartOptions = (height: number): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-500');
    const borderColor = getCSSVariableValue('--bs-gray-200');
    const baseColor = getCSSVariableValue('--bs-primary');
    const secondaryColor = getCSSVariableValue('--bs-gray-300');

    return {
      series: [
        {
          data: [44, 55, 57, 56, 61, 58],
        }
      ],
      chart: {
        fontFamily: 'inherit',
        type: typeGraph,
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin' , 'Juillet', 'Août', 'Septembre', 'Octobre ', 'Novembre ', 'Décembre'],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          /*formatter: function (val) {
            return '$' + val + ' thousands';
          },*/
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, selectedValue]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  return (
    <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
  );
};

export { ChartsWidget };
