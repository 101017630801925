import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PatientsValide} from './PatientsValide'

const ContentPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        <div className="card p-0 mb-0">
          <div className="card-header  p-2">
            <div className="card-toolbar flex-column w-100 m-0">
              <div className="d-flex flex-column w-100">
                  <div className="p-1">
                      <div className="fs-1 fw-bolder colorPrimary">Filtre</div>
                  </div>
                  <div className="separator border-gray-200"></div>
                  <div className="px-7 py-5" data-kt-user-table-filter="form">
                      <div className="contentFilter row col-md-12">

                        <div className="mb-5 col-md-2">
                            <label className="form-label fs-6 fw-bold">Nom</label>
                            <input type="text" className="form-control fw-bolder"></input>
                        </div>

                        <div className="mb-5 col-md-2">
                          <label className="form-label fs-6 fw-bold">Prénom</label>
                          <input type="text" className="form-control"></input>
                        </div>

                        <div className="mb-5 col-md-2">
                          <label className="form-label fs-6 fw-bold">Date de naissance</label>
                          <input type="date" className="form-control"></input>
                        </div>

                        <div className="mb-5 col-md-2">
                          <label className="form-label fs-6 fw-bold">Niveau de risque</label>
                          <select
                              className="form-select"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              data-kt-user-table-filter="sexe"
                              data-hide-search="true" >
                              <option value=""></option>
                              <option value="Elevé">Elevé</option>
                              <option value="Modéré">Modéré</option>
                              <option value="Faible">Faible</option>
                          </select>
                        </div>

                        <div className="mb-5 col-md-2">
                          <label className="form-label fs-6 fw-bold">Age</label>
                          <input type="text" className="form-control"></input>
                        </div>

                        <div className="mb-5 col-md-2">
                          <label className="form-label fs-6 fw-bold">Date d'admission</label>
                          <input type="date" className="form-control"></input>
                        </div>

                      </div>

                      <div className="row col-md-12">
                        <div className="offset-md-9 col-md-3 text-end">
                        <button
                              type="button"
                              className="btn btn-light btn-active-light-primary me-5 px-6"
                              data-kt-menu-dismiss="true"
                              data-kt-user-table-filter="reset">Réinitialiser
                          </button>
                          <button
                              type="button"
                              className="btn btn-primary fw-bold px-6"
                              data-kt-menu-dismiss="true"
                              data-kt-user-table-filter="filter">Appliquer
                          </button>
                        </div>
                          
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <PatientsValide className='p-0 mb-0 card-xxl-stretch mb-5' />
      </div>
    </>
  )
}

const DashboardPatientValide = () => {
  const intl = useIntl();
  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
  
  ]
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>{intl.formatMessage({id: 'MENU.PATIENT.VALIDE'})}</PageTitle>
      <ContentPage />
    </>
  )
}

export {DashboardPatientValide}
