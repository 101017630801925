/* eslint-disable jsx-a11y/anchor-is-valid */
import {ChangeEvent, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


const loginSchema = Yup.object().shape({
  username: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'AUTHENTICATION.VALIDATION.WRONG_EMAIL_FORMAT'
    )
    .required('AUTHENTICATION.VALIDATION.EMAIL_REQUIRED'),
  password: Yup.string().required('AUTHENTICATION.VALIDATION.PASSWORD_REQUIRED'),
})

const initialValues = {
  username: '',
  password: '',
}

export function Login() {
  const intl = useIntl()

  const [connexionCheck, setConnexionCheck] = useState("1");
  
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {

        let username="technique@impactdev.tn";
        let password="Sanomoov2023+"
        const {data: auth} = await login(username, password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.token)
        setCurrentUser(user);
        
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'AUTHENTICATION.VALIDATION.INVALID_LOGIN'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  function handleChange(event): void {
    console.log(event.target.value);
    setConnexionCheck(event.target.value);
  }

  return (
    <form
      className='form container px-0 mb-8'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid'>
        <img style={{width:'70px'}} src={toAbsoluteUrl('/media/logos/cercle.png')} />
        <h1 className='my-5 colorTitle fs-3'>{intl.formatMessage({id: 'AUTHENTICATION.Name.PAGE'})}</h1>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      <div className='row col-md-12 m-0'>
        <div className='col-md-6 LoginDigicap'>
            <div className='fv-row mb-3'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'AUTHENTICATION.INPUT.EMAIL'})}
              </label>

              <div className='input-group'>
                <span className='input-group-text'>
                  <i className='fas fa-user'></i>
                </span>
                <input
                  placeholder={intl.formatMessage({id: 'AUTHENTICATION.INPUT.EMAIL'})}
                  {...formik.getFieldProps('username')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.username && formik.errors.username},
                    {
                      'is-valid': formik.touched.username && !formik.errors.username,
                    }
                  )}
                  type='email'
                  name='username'
                  autoComplete='off'
                />
              </div>

              {formik.touched.username && formik.errors.username && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{intl.formatMessage({id: formik.errors.username})}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'AUTHENTICATION.INPUT.PASSWORD'})}
              </label>

              <div className='input-group'>
                <span className='input-group-text'>
                  <i className='fas fa-lock'></i>
                </span>
                <input
                  placeholder={intl.formatMessage({id: 'AUTHENTICATION.INPUT.PASSWORD'})}
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  type='password'
                  name='password'
                />
              </div>

              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{intl.formatMessage({id: formik.errors.password})}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-8 d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
              <div></div>
              <Link to='#' className='link-primary'>
                {intl.formatMessage({id: 'AUTHENTICATION.ForgotPassword'})}
              </Link>
            </div>

            <div className='d-grid mb-0  flex-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary  w-200px'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'AUTHENTICATION.LOGIN.BUTTON'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'AUTHENTICATION.PLEASE.WAIT'})}...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
        </div>
        <div className='col-md-6 LoginCPS'>

              <img
                  alt='carte cps'
                  className=''
                  src={toAbsoluteUrl('/media/auth/carte-cps.png')}
                />


                <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary btnCPS'
                disabled={formik.isSubmitting || !formik.isValid} >Se connecter avec la carte CPS</button>
                
        </div>
      </div>

      <div className='separator separator-content my-10'>
        <span className='text-gray-500 w-400px fw-bold fs-7'>S'identifier avec Pro santé connect</span>
      </div>

      <a href='#' className='card hover-elevate-up shadow-sm parent-hover py-3'>
      <div className='card-body p-0'>
        <div className='d-flex flex-row-auto flex-center'>
          <img
            alt='carte cps'
            className='mb-0 LogoProdSante'
            src={toAbsoluteUrl('/media/auth/prof-sante-traite.png')}
          />
        </div>
      </div>
      </a>  
      
    </form>
  )
}
