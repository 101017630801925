/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import { TwoFactor } from '../modules/auth/components/TwoFactor'


// const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const {twoFactor}   = useAuth()

  var twoFacterLocalStorage=localStorage.getItem("twoFactor");
  if(!twoFacterLocalStorage){
    twoFacterLocalStorage="false";
  }

  console.log(currentUser);
  console.log(twoFacterLocalStorage);

  return (
    <BrowserRouter >
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          
          { currentUser && twoFacterLocalStorage === "true" ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : currentUser && twoFacterLocalStorage === "false"  ? (
              
              <>
                

                <Route path='auth/*' element={<AuthPage />} />
                <Route path='auth/login' element={<Navigate to='/auth/twoFacter' />} />
                <Route path='*' element={<Navigate to='/auth' />} />
                

                
              </>
            ) : !currentUser  && twoFacterLocalStorage === "false"   ? (
              
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            ) : (
              <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )
          
          
          
          }
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
