import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Chart from 'react-apexcharts'
import { ChartsWidget } from './ChartsWidget'

const ContentPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  const listCycleVie = [
    {
      row:[
        {date: "03-01-2024",  status :"success" , actions: ["Admission à l’hôpital"], 
        actionsPerso :[
          {
            title : "Cholestérol",
            image : "pression-arterielle.svg",
          },
          {
            title : "Diabète",
            image : "diabete.svg",
          }
        ]},
        {date: "05-01-2024",  status :"success" , actions: ["Intervention médicale"] , actionsPerso :[]},
        {date: "15-01-2024",  status :"success" , actions: ["Sortie de l’hôpital"] , actionsPerso :[]},

       
      ]
      
    },
    {
      row:[
        {date: "20-02-2024",  status :"success" , actions: ["Consultation de suivi","Consultation de post intervention"] , actionsPerso :[]},
        {date: "05-03-2024",  status :"success"  , actions: ["Consultation de post intervention"] , actionsPerso :[]},
        {date: "12-03-2024",  status :"success" , actions: ["Consultation de suivi"] , actionsPerso :[]},
      ]
    },
    {
      row:[
        {date: "22-03-2023",  status :"success" , actions: ["Consultation de suivi"] , actionsPerso :[]},
        {date: "22-04-2024",  status :"success" , actions: ["Consultation de suivi"] , actionsPerso :[]},
        {date: "03-07-2024",  status :"primary" , actions: ["Consultation de suivi"] , actionsPerso :[]}
        
      ]
    }
  ]

  const listActes = [
    { type: "scatter", name: "Cholestérol",         image: "pression-arterielle.svg" , title: "titre graph Cholestérol"},
    { type: "scatter", name: "Pression artérielle", image: "cholesterol.svg"         , title: "titre graph Pression artérielle"},
    { type: "scatter", name: "Diabète",             image: "diabete.svg"             , title: "titre graph Diabète"},
    { type: "scatter", name: "Fonction rénale",     image: "fonction-renale.svg"     , title: "titre graph Fonction rénale"},
    { type: "scatter", name: "IMC",                 image: "poids.svg"               , title: "titre graph IMC"},
    { type: "bar",     name: "Activité physique",   image: "activite.svg"            , title: "titre graph Activité physique"},
    { type: "scatter", name: "Tabagisme",           image: "tabagisme.svg"           , title: "titre graph Tabagisme"},
    { type: "no",      name: "Traitement",          image: "traitements.svg"         , title: "titre graph Traitement"}
  ];

  const medicament = ["Médicament 1","Médicament 2","Médicament 3"]
  const dispensations=[
    {
      date:"22-04-2024",
      medicament
    },
    {
      date:"22-03-2023",
      medicament
    },
    {
      date:"12-03-2024",
      medicament
    }
  ];
  const SaisiePateint=[
    {
      date:"05-03-2024",
      medicament
    },
    {
      date:"20-02-2024",
      medicament
    },
    {
      date:"15-01-2024",
      medicament
    }
  ]

  /*const state = {
    options: {
      chart: {
        id: "basic-bar",
        
      },
      type: 'scatter',
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ]
  };*/


  return (
    <>
      <div className='row g-5 g-xl-8' >

        <div className="cardPatientInfo">
            <div className="p-0 row col-md-12">
              <div className="d-flex">

                <div className='col-md-4 flex-column rightborder  border-primary '>
                  <div className="p-4">
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                      <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start flex-wrap'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">ANA Simmone</span>
                                </div>

                                <div className="d-flex flex-wrap fw-semibold fs-4 pe-2">
                                  <span><b>Contact</b> : 06 77 63 44 54</span>
                                </div>
                            </div>
                            <div className='d-flex flex-column'></div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='col-md-4 flex-column rightborder  border-primary'>
                  <div className="p-4">
                    <div className='w-100'>

                    <div className="d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2">
                      <span><b>Diagnostic</b></span>
                    </div>

                    <div className="d-flex flex-wrap fw-semibold fs-4 pe-2">
                      <span>Syndrome coronarien aigu</span>
                    </div>

                    </div>
                  </div>
                </div>

                <div className='col-md-4 flex-column '>
                  <div className="p-4">
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                      <div className='flex-grow-1'>
                          <div >
                            <div className='d-flex flex-column'>
                                
                            </div>

                            <div className='d-flex flex-column'>
                                <div className="d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2">
                                  <span><b>Date de sortie :</b> 15/01/2024</span>
                                </div>

                                <div className="d-flex flex-wrap fw-semibold fs-4 pe-2">
                                  <span><b>Age :</b> 70 ans</span>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>

        <div className="card card-xxl-stretch mb-0">
          <div className="card-header p-2">
            <div className="card-toolbar flex-column w-100">
              <div className="d-flex flex-column w-100">
                <div className="fs-1 fw-bolder colorPrimary">
                   Documents et actes médicaux
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-2 row col-md-12 ">


        
          <label className="form-check-label colorSecondary fs-6 fw-bold link fs-4" htmlFor="flexCheckDefault">
            Télécharger les documents et actes médicaux présents sur le DMP 
            <i className="bi bi-file-earmark-arrow-down fs-2 mx-2"></i>
          </label>


          {
            listActes.map(function(item, i){
              return (
                <div className="p-4 col-md-4">
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>{item.name}</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                    <img  src={'../media/logos/'+item.image}  alt="pression-arterielle" data-toggle="tooltip" data-placement="top" title="Pression arterielle" />
                    </div>
                  </div>
                  <div className='contentBox mt-2'>
                    <div className="contentFilter row col-md-12">

                    {item.type === 'no' ? (

                      <div className='row col-md-12 mb-3 m-0'>
                         <div className='col-md-6'>
                            <h3 className='px-2 pb-2'>Dispensations</h3>
                            <div className="accordion" id="kt_accordion_1">
                              {
                                dispensations.map(function(item, i){
                                  
                                    return (
                                      <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target={'#kt_accordion_'+i+'_body_'+i}    aria-controls={'kt_accordion_'+i+'_body_'+i}>
                                                {item.date}
                                            </button>
                                        </h2>
                                        <div id={'kt_accordion_'+i+'_body_'+i} className="accordion-collapse collapse" aria-labelledby={'kt_accordion_'+i+'_header_'+i} data-bs-parent={'#kt_accordion_'+i}>
                                            <div className="accordion-body p-0">
                                                <div className='navi navi-bold navi-hover navi-active navi-link-rounded '>
      
      
                              
                                              <div className='navi-item mb-0'>
                                                <div className="menu menu-column w-200px">
                                                  {
                                                    medicament.map(function(med, m){
                                                    return (
                                                      <div className="menu-item">
                                                          <a href="#" className="menu-link">
                                                              <span className="menu-icon">
                                                                  <i className="bi bi-capsule fs-3"></i>
                                                              </span>
                                                              <span className="menu-title">{med}</span>
                                                          </a>
                                                      </div>
                                                    )
                                                    })
                                                  }
                                                </div>
      
                                              </div>
      
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    )
                                }
                                )
                              }
                            </div>
                         </div>
                         <div className='col-md-6'>
                            <h3 className='px-2 pb-2'>Saisies patient</h3>
                            <div className="accordion" id="kp_accordion_1">
                              {
                                SaisiePateint.map(function(item, i){
                                  
                                    return (
                                      <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target={'#kp_accordion_'+i+'_body_'+i}    aria-controls={'kp_accordion_'+i+'_body_'+i}>
                                                {item.date}
                                            </button>
                                        </h2>
                                        <div id={'kp_accordion_'+i+'_body_'+i} className="accordion-collapse collapse" aria-labelledby={'kp_accordion_'+i+'_header_'+i} data-bs-parent={'#kp_accordion_'+i}>
                                            <div className="accordion-body p-0">
                                                <div className='navi navi-bold navi-hover navi-active navi-link-rounded '>
      
      
                              
                                              <div className='navi-item mb-0'>
                                                <div className="menu menu-column w-200px">
                                                  {
                                                    medicament.map(function(med, m){
                                                    return (
                                                      <div className="menu-item">
                                                          <a href="#" className="menu-link">
                                                              <span className="menu-icon">
                                                                  <i className="bi bi-capsule fs-3"></i>
                                                              </span>
                                                              <span className="menu-title">{med}</span>
                                                          </a>
                                                      </div>
                                                    )
                                                    })
                                                  }
                                                </div>
      
                                              </div>
      
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    )
                                }
                                )
                              }
                            </div>
                         </div>
                      </div>

                      ) : (
                        <ChartsWidget className='card-xxl-stretch mb-5' typeGraph={item.type} />
                      )
                    }
                      
                      <h4 className='text-center text-capitalize my-3'>{item.title}</h4>
                    </div>
                  </div>
                </div>
              </div>
              )
            })  
          }   
        

          </div>

        </div>

        <div className="card card-xxl-stretch mb-0">
         <div className="card-header p-2">
            <div className="card-toolbar flex-column w-100">
              <div className="d-flex flex-column w-100">
                <div className="fs-1 fw-bolder colorPrimary">
                  Ligne de vie
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-2 row  position-relative">

          <div className="dropdown text-end">
            <button className="btn btn-primary btn-brand dropdown-toggle btnDropDown " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              2024
            </button>
            <ul className="dropdown-menu text-end" aria-labelledby="dropdownMenuButton1">
              <li className='link px-4 py-2'>2023</li>
              <li className='link px-4 py-2'>2022</li>
              <li className='link px-4 py-2'>2021</li>
              <li className='link px-4 py-2'>Tous</li>
            </ul>
          </div>

            <div className='py-11 container col-md-8 px-0'> {/*d-flex flex-column flex-center */}

              <div className='TimeLineDiGiCAP '> {/* text-center mb-11 d-flex flex-center flex-column flex-lg-row-fluid */}
                {
                  listCycleVie.map(function(list, i){
                    return (
                      <div className='ItemRow'>
                          {
                            list.row.map(function(item, j){
                              return (
                                <div className="item">


                                  {i === 1 && j===0  ? (
                                    <div className='lastItemInverse'></div>
                                  ) : (
                                    <span></span>
                                  )
                                 
                                  }
                                  
                                  
                                  <div className={"badge  badge-lg badge-"+item.status}>{item.date}</div>
                                  <div className='actions'>
                                    {item.actions.map(function(action, i){
                                        return (
                                            <span className='content'>
                                              <i className="fa fa-plus-square iconAction"></i>
                                              <span className='textAction'>{action}</span>
                                            </span>
                                        )
                                      })
                                    }
                                    {item.actionsPerso.map(function(actionPerso, i){
                                        return (
                                            <span className='content'>
                                              <img className='imageAction' src={'../media/logos/'+actionPerso.image} ></img>
                                              <span className='textAction'>{actionPerso.title}</span>
                                            </span>
                                        )
                                      })
                                    }
                                  </div> 

                                  { i === 0 && j===2 ? (
                                    <div className='lastItem'></div>
                                  ) : (
                                    <span></span>
                                  )
                                  
                                }

                                  
                                  
                                </div>
                              )
                            })
                          }
                          
                      </div>
                      

                      /*<div className=''>

                        i: {i}

                        
                      </div>*/

                      
                    )
                  })
                }
              </div>
            

            </div>

            <div className='py-11 container col-md-12 px-0'>
              <div className='text-end pb-3 w-150px'>
                <span className="badge mx-4 mb-2 badge-sm badge-primary badgeCycleVie">Evénement à venir</span>
                <span className="badge mx-4 badge-sm badge-success badgeCycleVie">Evénement passé</span>
              </div>
            </div>
            
            
          </div>
        </div>

      </div>
      {/* end::Row  */}
    </>
  )
}

const DashboardSuiviPatient = () => {
  const intl = useIntl();
  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({id: 'MENU.PATIENT.VALIDE'}),
      path: '/patientsvalide',
      isSeparator: false,
      isActive: false,
    },
  
  ]
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>{intl.formatMessage({id: 'MENU.PAGE.PATIENT.SUIVI'})}</PageTitle>
      <ContentPage />
    </>
  )
}

export {DashboardSuiviPatient}
