/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
export function DashbordAuth() {

  const intl = useIntl()
  let navigate = useNavigate(); 
  const routeChange = (path) =>{ 
    navigate(path);
  }

  return (
    <div className='text-center mb-6 d-flex flex-center flex-column flex-lg-row-fluid'>
      <div className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid'>
        <img style={{width: '130px'}} src={toAbsoluteUrl('/media/logos/cercle.png')} />
        <h1 className='my-8 colorTitle '>{intl.formatMessage({id: 'AUTHENTICATION.Name.PAGE'})}</h1>
      </div>
      <span className='w-md-400px mb-10 colorDesc'>
        {intl.formatMessage({id: 'AUTHENTICATION.DESC.PAGE'})}
      </span>
      <div className='actionBtn d-flex flex-center flex-column align-items-stretch w-md-300px'>
        <button
          type='submit'
          className='btn btn-primary mb-4 BtnRound emptyBtn'
          onClick={() => routeChange('login')}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'AUTHENTICATION.QSignIn.BUTTON'})}
          </span>
        </button>

        <button
          type='submit'
          className='btn btn-primary mb-8 BtnRound'
          onClick={() => routeChange('login')}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'AUTHENTICATION.QSignUP.BUTTON'})}
          </span>
        </button>

        <div className='fw-semibold text-primary fs-base mb-8'>
          <a href='#' className='underlineText' target='_blank'>
            {intl.formatMessage({id: 'AUTHENTICATION.SignIn.BUTTON'})}
          </a>
        </div>
      </div>
    </div>
  )
}
