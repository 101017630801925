import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {DashbordAuth} from './components/DashbordAuth'
import {AuthLayout} from './AuthLayout'
import { TwoFactor } from './components/TwoFactor'
import { TwoFactorSMS } from './components/TwoFactor-sms'


const AuthPage = () => (

  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='DashbordAuth' element={<DashbordAuth />} />
      <Route path='twoFacter' element={<TwoFactor />} />
        
      <Route path='twoFacter/sms' element={<TwoFactorSMS />} />
      
    
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<DashbordAuth />} />
    </Route>
  </Routes>
)

export {AuthPage}
