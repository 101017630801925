import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  TablesWidget9,
} from '../../../_metronic/partials/widgets'
import { ChartsWidget } from './ChartsWidget'

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        {/* <div className='col-xxl-4'>
          <MixedWidget8 className='card-xxl-stretch' chartColor='warning' chartHeight='150px' />
        </div> */}
        {/* end::Col  */}
        {/* begin::Col  */}
        <div>
          <ChartsWidget className='card-xxl-stretch mb-5' typeGraph='scatter' />
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}
    </>
  )
}

const DashboardWrapper4 = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Mes suivis'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper4}
