/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'


type Props = {
  className: string
}

const PatientsValide: React.FC<Props> = ({className}) => {

  const intl = useIntl();

  const listPatients = [
    { name: "Ana Simmons",     dateBirth: "15/01/1999" , diagnostic: "Cardiovasculaire" , desc: "Syndrome coronarien",                     dateSortie: "15/01/2024", id: 1 },
    { name: "Jessie Clarcson", dateBirth: "25/10/2000" , diagnostic: "Cardiovasculaire" , desc: "Revasculaire coronarienne hors urgences", dateSortie: "25/01/2024", id: 2 },
    { name: "Lebron Wayde",    dateBirth: "20/05/1990" , diagnostic: "Cardiovasculaire" , desc: "Revasculaire coronarienne hors urgences", dateSortie: "30/01/2024", id: 3 },
    { name: "Natali Goodwin",  dateBirth: "20/05/1996" , diagnostic: "Cardiovasculaire" , desc: "Syndrome coronarien",                     dateSortie: "04/02/2024", id: 4 },
    { name: "Kevin Leonard",   dateBirth: "20/05/1997" , diagnostic: "Cardiovasculaire" , desc: "Revasculaire coronarienne hors urgences", dateSortie: "22/02/2024", id: 5 },
    { name: "Ana Simmons",     dateBirth: "15/01/1999" , diagnostic: "Cardiovasculaire" , desc: "Revasculaire coronarienne hors urgences", dateSortie: "23/02/2024", id: 6 },
    { name: "Jessie Clarcson", dateBirth: "25/10/2000" , diagnostic: "Cardiovasculaire" , desc: "Syndrome coronarien",                     dateSortie: "02/03/2024", id: 7 },
    { name: "Lebron Wayde",    dateBirth: "20/05/1990" , diagnostic: "Cardiovasculaire" , desc: "Revasculaire coronarienne hors urgences", dateSortie: "08/03/2024", id: 8 },
    { name: "Natali Goodwin",  dateBirth: "20/05/1996" , diagnostic: "Cardiovasculaire" , desc: "Syndrome coronarien",                     dateSortie: "29/03/2024", id: 9 },
    { name: "Kevin Leonard",   dateBirth: "20/05/1997" , diagnostic: "Cardiovasculaire" , desc: "Revasculaire coronarienne hors urgences", dateSortie: "30/03/2024", id: 10 }
  ];

  let navigate = useNavigate(); 
  const routeChange = (path) =>{ 
    navigate(path);
  }


  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-6">
        <h3 className="card-title align-items-start flex-column w-100">
        <span className="card-label fw-bold text-gray-800">{intl.formatMessage({id: 'MENU.PATIENT.VALIDE'})}</span>
            <span className="text-gray-500 mt-1 fw-semibold fs-6">Affichage 10 sur 22 patients</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}

        
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 patientValide'>
            {/* begin::Table head */}
            <thead>

              <tr className='fw-bold text-muted'>
                <th className='min-w-150px  table-sort-asc pointer' data-sort="asc">Patients</th>
                <th className='min-w-140px'>Diagnostic</th>
                <th className='min-w-100px'>Date de sortie</th>
                <th className='min-w-100px'>Alertes</th>
                <th className='min-w-100px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                listPatients.map(function(item, i){
                  return (
                    <tr  key={item.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='form' className='text-dark fw-bold text-hover-primary fs-6'>
                              {item.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {item.dateBirth}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span  className='text-dark fw-bold text-hover-primary fs-6'>
                              {item.diagnostic}
                            </span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {item.desc}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                          {item.dateSortie}
                        </span>
                      </td>
                      <td>


                        {/*<OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              Tooltip text
                            </Tooltip>
                          )} >
                          <span className="bg-transparent border-0">
                            <i className="bi bi-exclamation-circle fs-2 text-danger"></i>
                          </span>
                        </OverlayTrigger>*/}


                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='actions suivie-icon'>
                                <img src="media/logos/pression-arterielle.svg" alt="pression-arterielle" data-toggle="tooltip" data-placement="top" title="Pression arterielle" />
                                <img src="media/logos/cholesterol.svg" alt="cholesterol" data-toggle="tooltip" data-placement="top" title="Cholesterol" />
                                <img src="media/logos/tabagisme.svg" alt="tabagisme" data-toggle="tooltip" data-placement="top" title="Tabagisme" />
                                <img src="media/logos/activite.svg" alt="activite" data-toggle="tooltip" data-placement="top" title="Activite" />
                              </span>
                              <span className='actions suivie-icon'>
                                <img src="media/logos/poids.svg" alt="poids" data-toggle="tooltip" data-placement="top" title="Poids" />
                                <img src="media/logos/diabete.svg" alt="diabete" data-toggle="tooltip" data-placement="top" title="Diabete" />
                                <img src="media/logos/traitements.svg" alt="traitements" data-toggle="tooltip" data-placement="top" title="Traitements" />
                                <img src="media/logos/fonction-renale.svg" alt="fonction renale" data-toggle="tooltip" data-placement="top" title="Fonction renale" />
                              </span>
                            </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0 me-5'>
                          <span className="link" onClick={() => routeChange('/patientsvalide/suivis')}  >Voir</span>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <div className="row">
        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <div id="kt_table_users_paginate">
                <ul className="pagination">
                    <li className="page-item disabled"><a className="page-link">Premier</a></li>
                    <li className="page-item previous"><a className="page-link page-text me-5">Précédent</a></li>
                    <li className="page-item active"><a className="page-link">1</a></li>
                    <li className="page-item"><a className="page-link">2</a></li>
                    <li className="page-item"><a className="page-link">3</a></li>
                    <li className="page-item next"><a className="page-link page-text">Suivant</a></li>
                    <li className="page-item"><a className="page-link">Dernier</a></li>
                </ul>
            </div>
        </div>
        </div>
      </div>

    </div> 
  )
}

export {PatientsValide}